import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe3.css'

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import cardfoto1 from "../images/BacalhauaBraz_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/BacalhauLegumes_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/BacalhaucomGrao_baixa.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image



const ReceitaC = () => (
  <Layout>
    <SEO title="Bacalhau Mil e Uma Maneiras - Receita 2" />

    <div id="rec3-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo playfair-black">
            Salada de Bacalhau com grão e coentros
        </div>
        <div className="desc sneak-regular">
        A salada de bacalhau com grão é um prato nutritivo, que garante todos os benefícios do bacalhau, sem descurar o sabor das suculentas lascas.
        </div>
        </div>
    </div>
    <div id="rec3-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de filetes de Bacalhau Ponto de Sal
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Cebola roxa
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                        Lata de grão de bico cozido
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            10cl 
                        </div>
                        <div className="text sneak-regular">  
                         Azeite
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5cl 
                        </div>
                        <div className="text sneak-regular">  
                         Vinagre de vinho branco
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2
                        </div>
                        <div className="text sneak-regular">  
                         Ovos
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                         Azeitonas pretas
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Coentros
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Sal e pimenta
                        </div>
                        
                    </div>
                  
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                        Deite o grão num coador e passe-o por água corrente. Escorra muito bem e deite numa taça.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 - 
                        </div>
                        <div className="text sneak-regular">  
                        Adicione o bacalhau em lascas previamente cozido e arrefecido. Junte a cebola picada e os coentros picados. Regue com o azeite e o vinagre.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Misture cuidadosamente.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Enfeite com os ovos cozidos e cortados em metades, juntamente com as azeitonas                    
                          </div>
                        
                    </div>
       
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            307
                        </div>
                        <div className="titulo sneak-regular">
                            14
                        </div>
                        <div className="titulo sneak-regular">
                           2
                        </div>
                        <div className="titulo sneak-regular">
                          15
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                          31
                        </div>
                        <div className="titulo sneak-regular">
                         5
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
    {/* <div id="rec3-section-3">
        <div className="wrapper">
            <div className="left-col">
            <img src={cardfoto3} alt="Logo" />

            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                        Este vídeo exemplificativo contém todos os passos necessários para elaborar a receita utilizando as melhores técnicas.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div> */}


  </Layout>
)


export default ReceitaC
